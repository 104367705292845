const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://alfast.dvgeo.app' : 'http://192.168.100.106:3059',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://alfast.dvgeo.app' : 'http://192.168.100.106:3059',
    api: 'api/',
    apiSocket: 'alfast::1.2',
    nameDir: 'alfast',
    package: 'pe.alfast.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyAzhF7HU0hi4lteNyWU_pebuWeyKWjgpiY',
    appName: 'ALFAST',
    provider: '',
    colorPrimary: '#1A1A1A',
    colorDark: '#000000',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'pe.alfast.passenger',
    playStoreDriverId: 'pe.alfast.driver',
    appStorePassengerId: '6738797563',
    appStoreDriverId: '6738797567',
    email: "viconim@gmail.com",
};
export default config;
